@font-face {
  font-family: steradian;
  font-weight: 100;
  src: url(../assets/fonts/steradian-ultralight/font.woff) format("woff");
}
@font-face {
  font-family: steradian;
  font-weight: 300;
  src: url(../assets/fonts/steradian-light/font.woff) format("woff");
}
@font-face {
  font-family: steradian;
  font-weight: 400;
  src: url(../assets/fonts/steradian-regular/font.woff) format("woff");
}
@font-face {
  font-family: steradian;
  font-weight: 500;
  src: url(../assets/fonts/steradian-bold/font.woff) format("woff");
}
