body {
  background: #eaebf3;
}

html,
body,
text {
  color: #4e4b66;
  font-family: steradian, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

.rdp-day_selected:not([disabled]),
.rdp-day_selected:focus:not([disabled]),
.rdp-day_selected:active:not([disabled]),
.rdp-day_selected:hover:not([disabled]) {
  background-color: #58469b !important;
}

.rdp-button:focus:not([disabled]),
.rdp-button:active:not([disabled]) {
  border: none !important;
}

.rdp-day {
  border-radius: 0 !important;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 0 !important;
}

.rdp-day_range_middle:not([disabled]) {
  background: #9b90c3 !important;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: normal !important;
}
